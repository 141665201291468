import { inject } from '@angular/core';
import { patchState, signalStore,withMethods, withState } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { debounceTime, distinctUntilChanged, pipe, switchMap, tap } from 'rxjs';
import { PromoService } from '../../services/promos.service';

export interface PromoState {
    promoLoader: boolean;
    promoData: any;
    promoCount: number
};
  
const initialPromoState: PromoState = {
    promoLoader: false,
    promoData: [],
    promoCount: 0
};

export const PromoStore = signalStore(
    withState<PromoState>(initialPromoState),
    withMethods(({...store},promos = inject(PromoService)) => ({
      loadPromos: rxMethod<void>(
  pipe(
    debounceTime(300),
    distinctUntilChanged(),
    switchMap(()=> {
      
      return promos.getPromos().pipe(
        tap((comps: any) => {
          patchState(store, { promoData: comps })
          return comps;
        }))
    })
  )
      ),
      
      resetAccountState() {
        return patchState(store, initialPromoState);
      },
    }))
  );