import { Injectable } from '@angular/core';
import { environment } from '../config/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PromoService {
  public apiUrl = environment.apiUrl;
  constructor(private http: HttpClient) { }

  getPromos(appName='AR') {
    // this.apiUrl =  environment.apiUrl;
    return this.http.post<any>(this.apiUrl + '/dashboard/getPromocode',{ data: appName })
      .pipe(
        map(promos => {
            return promos;
        })
      );
  }
}